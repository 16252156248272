import React, { useState, useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IESVBar from "../charts/iesVBar";
import useStores from "../../hooks/use-stores";
import { values } from "mobx";
import { CHART_BAR_COLOR, CHART_BAR_WIDTH, CHART_HEIGHT, CHART_WIDTH, THEME_CLASS } from "../../constants/style";
import { observer } from "mobx-react";
import { getCurrentThemeColour } from '../../constants/style';
import { useTranslation } from 'react-i18next';

const BehaviorOverviewAdvanceSearch = observer(({
  showCommendation, showIncident, showSuspension, showDetention
}) => {
  const { t } = useTranslation();
  const { behaviorStore } = useStores();
  const [commendation, setCommendation] = useState<any>([]);
  const [commendationData, setCommendationData] = useState<any>([]);
  const [incident, setIncident] = useState<any>([]);
  const [incidentData, setIncidentData] = useState<any>([]);
  const [suspension, setSuspension] = useState<any>([]);
  const [suspensionData, setSuspensionData] = useState<any>([]);
  const [detention, setDetention] = useState<any>([]);
  const [detentionData, setDetentionData] = useState<any>([]);

  useEffect(() => {
    var themeClass: any = THEME_CLASS[0];
    if (behaviorStore.behaviorAdvanceData.length > 0) {
      behaviorStore.behaviorAdvanceData.map((d: any) => {
        if (d.category == "Commendation") {
          let tempLabel: any[] = [];
          let tempAmount: any[] = [];

          d.detailData.length > 0 && d.detailData.map((dd: any, index: number) => {
            tempLabel.push(dd.label);
            tempAmount.push({
              value: dd.amount,
              itemStyle: {
                color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
              }
            });
          })
          setCommendation(tempLabel);
          setCommendationData(tempAmount);
        }
        else if (d.category == "Incident") {
          let tempLabel: any[] = [];
          let tempAmount: any[] = [];

          d.detailData.length > 0 && d.detailData.map((dd: any, index: number) => {
            tempLabel.push(dd.label);
            tempAmount.push({
              value: dd.amount,
              itemStyle: {
                color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
              }
            });
          })
          setIncident(tempLabel);
          setIncidentData(tempAmount);
        }
        else if (d.category == "Detention") {
          let tempLabel: any[] = [];
          let tempAmount: any[] = [];
          d.detailData.length > 0 && d.detailData.map((dd: any, index: number) => {
            tempLabel.push(dd.label);
            tempAmount.push({
              value: dd.amount,
              itemStyle: {
                color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
              }
            });
          })
          setDetention(tempLabel);
          setDetentionData(tempAmount);
        } else {
          let tempLabel: any[] = [];
          let tempAmount: any[] = [];

          d.detailData.length > 0 && d.detailData.map((dd: any, index: number) => {
            tempLabel.push(dd.label);
            tempAmount.push({
              value: dd.amount,
              itemStyle: {
                color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
              }
            });
          })

          setSuspension(tempLabel);
          setSuspensionData(tempAmount);
        }
      })
    }

  }, [behaviorStore.behaviorAdvanceData]);

  return (
    behaviorStore.behaviorAdvanceData.length ?
      <div className="m-10 grid grid-cols-2 gap-4">
        {
          showCommendation &&
          <div>
            <div className="text-center text-barPrimary text-xl my-4">{t('COMMENDATION')}</div>
            <IESVBar
              dataAndSetting={{
                data: {
                  names: commendation.length > 0 ? commendation : [t('NO_RESULT')],
                  values: commendationData.length > 0 ? commendationData : [0]
                },
                height: CHART_HEIGHT,
                width: CHART_WIDTH,
                initialShow: 10,
                xAxisLabelRotate: 0,
                barColor: THEME_CLASS[0].chartColorLibrary[0],
                barWidth: CHART_BAR_WIDTH
              }}
            />
          </div>
        }

        {
          showIncident &&
          <div>
            <div className="text-center text-barPrimary text-xl my-4">{t('INCIDENT')}</div>
            <IESVBar
              dataAndSetting={{
                data: {
                  names: incident.length > 0 ? incident : [t('NO_RESULT')],
                  values: incidentData.length > 0 ? incidentData : [0]
                },
                height: CHART_HEIGHT,
                width: CHART_WIDTH,
                initialShow: 10,
                xAxisLabelRotate: 0,
                barColor: THEME_CLASS[0].chartColorLibrary[0],
                barWidth: CHART_BAR_WIDTH
              }}
            />
          </div>
        }

        {
          showSuspension &&
          <div>
            <div className="text-center text-barPrimary text-xl my-4">{t('SUSPENSION')}</div>
            <IESVBar
              dataAndSetting={{
                data: {
                  names: suspension.length > 0 ? suspension : [t('NO_RESULT')],
                  values: suspensionData.length > 0 ? suspensionData : [0]
                },
                height: CHART_HEIGHT,
                width: CHART_WIDTH,
                initialShow: 10,
                xAxisLabelRotate: 0,
                barColor: THEME_CLASS[0].chartColorLibrary[0],
                barWidth: CHART_BAR_WIDTH
              }}
            />
          </div>
        }

        {
          showDetention &&
          <div>
            <div className="text-center text-barPrimary text-xl my-4">{t('DETENTION')}</div>
            <IESVBar
              dataAndSetting={{
                data: {
                  names: detention.length > 0 ? detention : [t('NO_RESULT')],
                  values: detentionData.length > 0 ? detentionData : [0]
                },
                height: CHART_HEIGHT,
                width: CHART_WIDTH,
                initialShow: 10,
                xAxisLabelRotate: 0,
                barColor: THEME_CLASS[0].chartColorLibrary[0],
                barWidth: CHART_BAR_WIDTH
              }}
            />
          </div>
        }

      </div>
      : <div className="w-full text-barPrimary text-3xl text-center p-10">{t('CHART_AREA')}</div>
  )
});

export default BehaviorOverviewAdvanceSearch;