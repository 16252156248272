import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button, CircularProgress } from "@material-ui/core";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import BehaviorOverviewAdvanceSearch from '../../components/behavior/behaviour-overview-advanced'
import AdvanceSearchModal from '../../components/advance-search-modal';
import { toJS } from 'mobx';
import IESVBar from '../../components/charts/iesVBar';
import { CHART_BAR_COLOR, CHART_BAR_WIDTH, CHART_HEIGHT, CHART_WIDTH, THEME_CLASS } from '../../constants/style';
import { useTranslation } from 'react-i18next';
import VMCard from '../../components/collapsed-card';

const BehaviorPage = observer(() => {
  const { behaviorStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [activedFunctions, setActiveFunction] = useState([]);
  const [yearFrom, setYearFrom] = useState("");
  const [yearTo, setYearTo] = useState("");
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [behaviorList, setBehaviorList] = useState({
    all: false,
    commendation: false,
    incident: false,
    detention: false,
    suspension: false,
  });
  const [commendationData, setCommendationData] = useState<any>();
  const [incidentData, setIncidentData] = useState<any>();
  const [suspensionData, setSuspensionData] = useState<any>();
  const [detentionData, setDetentionData] = useState<any>();

  useEffect(() => {
    // filterStore.getSemesterDateList().then(() => {
    if (filterStore.academicYearList.length > 0) {
      if (filterStore.currentSemester == 0) filterStore.getCurrentSemester();
      let fromDate = "";
      let toDate = "";
      let yearDescription = "";
      let semesterDescription = "";
      let lastSemester = filterStore.currentSemester;
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(s => s.id == lastSemester)) {
          yearDescription = year.yearDescription;
          let semesterObj = year.academicSemesters.find(s => s.id == lastSemester);
          semesterDescription = semesterObj.semesterDescription;
          fromDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
          toDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
          break;
        }
      }

      behaviorStore.getBehaviorOverview({
        fromDate,
        toDate,
        semesterIdList: [lastSemester],
        classCode: ""
      }).then((response) => {
        if (response.data.length > 0) {
          setCommendationData(response.data.filter((data) => data.category == "Commendation")[0].detailData);
          setDetentionData(response.data.filter((data) => data.category == "Detention")[0].detailData);
          setSuspensionData(response.data.filter((data) => data.category == "Suspension")[0].detailData);
          setIncidentData(response.data.filter((data) => data.category == "Incident")[0].detailData);
        } else {
          lastSemester -= 1;
          for (var year of filterStore.academicYearList) {
            if (year.academicSemesters.find(s => s.id == lastSemester)) {
              yearDescription = year.yearDescription;
              let semesterObj = year.academicSemesters.find(s => s.id == lastSemester);
              semesterDescription = semesterObj.semesterDescription;
              fromDate = semesterObj.academicTerms[0].academicTermDates.find((data) => data.yearLevelId > 0).startDate;
              toDate = semesterObj.academicTerms[1].academicTermDates.find((data) => data.yearLevelId > 0).endDate;
              break;
            }
          }
          behaviorStore.getBehaviorOverview({
            fromDate,
            toDate,
            semesterIdList: [lastSemester],
            classCode: ""
          }).then((response) => {
            if (response.data.length > 0) {
              setCommendationData(response.data.filter((data) => data.category == "Commendation")[0].detailData);
              setDetentionData(response.data.filter((data) => data.category == "Detention")[0].detailData);
              setSuspensionData(response.data.filter((data) => data.category == "Suspension")[0].detailData);
              setIncidentData(response.data.filter((data) => data.category == "Incident")[0].detailData);
              filterStore.selectedYear = yearDescription;
              filterStore.selectedSemester = semesterDescription;
            }
          });
        }
      })
      filterStore.selectedYear = yearDescription;
      filterStore.selectedSemester = semesterDescription;
    }
    // filterStore.getSemesterBasedOnYear(new Date().getFullYear().toString());
    // var selectedSemester = filterStore.semesterList.find(e => e.id == filterStore.currentSemester);
    // if (selectedSemester.academicTerms[0].academicTermDates.length > 0) {
    //   var startTerm = toJS(selectedSemester).academicTerms[0].academicTermDates.find(e => e.yearLevelId > 0);
    //   var endTerm = toJS(selectedSemester).academicTerms[1].academicTermDates.find(e => e.yearLevelId > 0);
    //   setYearFrom(startTerm.startDate);
    //   setYearTo(endTerm.endDate);
    //   console.log("before apply filter", {
    //     fromDate: startTerm.startDate,
    //     toDate: endTerm.endDate,
    //     semesterIdList: [filterStore.currentSemester],
    //     classCode: ""
    //   });
    //   behaviorStore.getBehaviorOverview(
    //     {
    //       fromDate: startTerm.startDate,
    //       toDate: endTerm.endDate,
    //       semesterIdList: [filterStore.currentSemester],
    //       classCode: ""
    //     }
    //   ).then((response) => {
    //     if (response.data.length > 0) {
    //       setCommendationData(response.data.filter((data) => data.category == "Commendation")[0].detailData);
    //       setDetentionData(response.data.filter((data) => data.category == "Detention")[0].detailData);
    //       setSuspensionData(response.data.filter((data) => data.category == "Suspension")[0].detailData);
    //       setIncidentData(response.data.filter((data) => data.category == "Incident")[0].detailData);
    //     }
    //   });
    // }
    // filterStore.selectedYear = new Date().getFullYear().toString();
    // filterStore.selectedSemester = filterStore.academicYearList
    //   .filter((a) => a.yearDescription == filterStore.selectedYear)[0].academicSemesters
    //   .filter((sem) => sem.id == filterStore.currentSemester)[0].semesterDescription
    // });
  }, [filterStore.academicYearList]);

  const getOverViewData = (req) => {
    console.log("apply filter", {
      fromDate: req.startDate,
      toDate: req.endDate,
      semesterIdList: req.semesterList,
      classCode: ""
    });
    setYearFrom(req.startDate);
    setYearTo(req.endDate);
    behaviorStore.getBehaviorOverview({
      fromDate: req.startDate,
      toDate: req.endDate,
      semesterIdList: req.semesterList,
      classCode: ""
    }).then((response) => {
      console.log(response);
      if (response.data.length > 0) {
        setCommendationData(response.data.filter((data) => data.category == "Commendation")[0].detailData);
        setDetentionData(response.data.filter((data) => data.category == "Detention")[0].detailData);
        setSuspensionData(response.data.filter((data) => data.category == "Suspension")[0].detailData);
        setIncidentData(response.data.filter((data) => data.category == "Incident")[0].detailData);
      }
    });
  }

  const onApplyAdvanceSearch = (dataInfo: any) => {

    setActiveFunction([]);
    setBehaviorList(dataInfo.behavior);
    var startDate = "";
    var endDate = "";
    var filteredSemesterList = filterStore.semesterListForAdvance.filter(sem => sem.id == dataInfo.semester);
    for (var sem of filteredSemesterList) {
      // First term
      if (sem.academicTerms[0].academicTermDates.length > 0) {
        var found = false;
        for (var firstTerm of sem.academicTerms[0].academicTermDates) {
          if (firstTerm.yearLevelId == dataInfo.yearLevel) {
            found = true;
            startDate = firstTerm.startDate;
            break;
          }
        }

        if (!found) {
          let levelZero = sem.academicTerms[0].academicTermDates.filter(a => a.yearLevelId == 0);
          for (var l of levelZero) {
            startDate = l.startDate;
            break;
          }
        }
      } else {
        startDate = `${dataInfo.year}-01-01`;
      }
      // Second term
      if (sem.academicTerms[1].academicTermDates.length > 0) {
        for (var secondTerm of sem.academicTerms[1].academicTermDates) {
          if (secondTerm.yearLevelId == dataInfo.yearLevel) {
            found = true;
            endDate = secondTerm.endDate;
            break;
          }
        }
        if (!found) {
          let levelZero = sem.academicTerms[1].academicTermDates.filter(a => a.yearLevelId == 0);
          for (var l of levelZero) {
            endDate = l.endDate;
            break;
          }
        }
      } else {
        endDate = `${dataInfo.year}-12-31`;
      }
    }
    setFromDate(startDate);
    setToDate(endDate);

    var finalClassList;
    if (dataInfo.classCodeList && dataInfo.classCodeList.length == 1 && dataInfo.classCodeList[0] == "All") {
      var tempList = [];
      for (var c of filterStore.classList) {
        tempList.push(c.homeroomCode);
      }
      finalClassList = tempList;
    }
    else
      finalClassList = dataInfo.classCodeList;

    if (!dataInfo.semester && dataInfo.semester == 0) return;
    let reqData =
    {
      fromDate: new Date(startDate),
      toDate: new Date(endDate),
      semesterIdList: [dataInfo.semester],
      classCode: finalClassList,
    };

    console.log(reqData);

    behaviorStore.getBehaviorAdvanceData(reqData);
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showStaffList={false}
          showYearSemTerm={true}
          showFilter={true}
          showClassCode={false}
          showYearRange={false}
          showDomain={false}
          onApplyFilter={getOverViewData}
        >
          <title>Behavioral</title>
          <div className="col-span-4">
            {/* Overview */}
            <div className="m-2 p-4 shadow-lg rounded-xl border">
              <div className="px-4 text-barPrimary text-xl font-bold grid grid-cols-2">
                <div className="py-2 uppercase">{t('BEHAVIOR')}:
                  <span className="font-normal ml-2">{filterStore.selectedYear} - {filterStore.selectedSemester.includes('Semester') ? filterStore.selectedSemester : `Semester ${filterStore.selectedSemester}`}</span>
                </div>
                <div className="text-right">
                  <Button
                    className="bg-buttonPrimary text-white p-2"
                    component="span"
                    onClick={() => setShowAdvanceSearch(true)}
                  >
                    {t('ADVANCED_SEARCH')}
                  </Button>
                </div>
              </div>
            </div>

            <VMCard className="m-2 mt-4">
              {
                filterStore.loading || behaviorStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <>
                    <div className="grid grid-cols-2 px-10 py-4">
                      <div>
                        <p className="text-center text-barPrimary text-xl my-4">{t('COMMENDATION')}</p>
                        <IESVBar
                          dataAndSetting={{
                            data: {
                              names: commendationData && commendationData.length > 0 ? [commendationData[0].label] : [t('NO_RESULT')],
                              values: commendationData && commendationData.length > 0 ? [commendationData[0].amount] : [0]
                            },
                            height: CHART_HEIGHT,
                            width: CHART_WIDTH,
                            initialShow: 100,
                            xAxisLabelRotate: 0,
                            barColor: THEME_CLASS[0].chartColorLibrary[0],
                            barWidth: CHART_BAR_WIDTH
                          }}
                        />
                      </div>
                      <div>
                        <p className="text-center text-barPrimary text-xl my-4">{t('INCIDENT')}</p>
                        <IESVBar
                          dataAndSetting={{
                            data: {
                              names: incidentData && incidentData.length > 0 ? [incidentData[0].label] : [t('NO_RESULT')],
                              values: incidentData && incidentData.length > 0 ? [incidentData[0].amount] : [0]
                            },
                            height: CHART_HEIGHT,
                            width: CHART_WIDTH,
                            initialShow: 10,
                            xAxisLabelRotate: 0,
                            barColor: THEME_CLASS[0].chartColorLibrary[1],
                            barWidth: CHART_BAR_WIDTH
                          }}
                        />

                      </div>

                      <div className="my-6">
                        <p className="text-center text-barPrimary text-xl my-4">{t('SUSPENSION')}</p>
                        <IESVBar
                          dataAndSetting={{
                            data: {
                              names: suspensionData && suspensionData.length > 0 ? [suspensionData[0].label] : [t('NO_RESULT')],
                              values: suspensionData && suspensionData.length > 0 ? [suspensionData[0].amount] : [0]
                            },
                            height: CHART_HEIGHT,
                            width: CHART_WIDTH,
                            initialShow: 10,
                            xAxisLabelRotate: 0,
                            barColor: THEME_CLASS[0].chartColorLibrary[2],
                            barWidth: CHART_BAR_WIDTH
                          }}
                        />
                      </div>
                      <div className="my-6">
                        <p className="text-center text-barPrimary text-xl my-4">{t('DETENTION')}</p>
                        <IESVBar
                          dataAndSetting={{
                            data: {
                              names: detentionData && detentionData.length > 0 ? [detentionData[0].label] : [t('NO_RESULT')],
                              values: detentionData && detentionData.length > 0 ? [detentionData[0].amount] : [0]
                            },
                            height: CHART_HEIGHT,
                            width: CHART_WIDTH,
                            initialShow: 10,
                            xAxisLabelRotate: 0,
                            barColor: THEME_CLASS[0].chartColorLibrary[3],
                            barWidth: CHART_BAR_WIDTH
                          }}
                        />
                      </div>

                    </div>
                    <div className="mx-6 my-4">
                      <table className="w-full">
                        <thead className="bg-barPrimary text-white">
                          <th className="w-16 px-8 text-left text-bold">{t('SUMMARY')}</th>
                          <th className="w-16 px-8 text-right"></th>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="w-16 px-8">{t('COMMENDATION')}</td>
                            <td className="w-16 px-8 text-right">{commendationData && commendationData.length && commendationData[0].amount}</td>
                          </tr>
                          <tr className="bg-gray-100">
                            <td className="w-16 px-8">{t('INCIDENT')}</td>
                            <td className="w-16 px-8 text-right">{incidentData && incidentData.length && incidentData[0].amount}</td>
                          </tr>
                          <tr>
                            <td className="w-16 px-8">{t('DETENTION')}</td>
                            <td className="w-16 px-8 text-right">{detentionData && detentionData.length && detentionData[0].amount}</td>
                          </tr>
                          <tr className="bg-gray-100">
                            <td className="w-16 px-8">{t('SUSPENSION')}</td>
                            <td className="w-16 px-8 text-right">{suspensionData && suspensionData.length && suspensionData[0].amount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
              }
            </VMCard>

            <AdvanceSearchModal
              showModal={showAdvanceSearch}
              closeModal={() => setShowAdvanceSearch(false)}
              showAcademicYear={true}
              showSemester={true}
              showYearLevel={true}
              otherFilter={["behavior"]}
              onApplyFilter={onApplyAdvanceSearch}
            >
              {
                behaviorStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <BehaviorOverviewAdvanceSearch
                    showCommendation={behaviorList.commendation || behaviorList.all}
                    showDetention={behaviorList.detention || behaviorList.all}
                    showSuspension={behaviorList.suspension || behaviorList.all}
                    showIncident={behaviorList.incident || behaviorList.all}
                  />
              }

            </AdvanceSearchModal>

          </div>
        </Layout>
      }

    </>
  )
})

export default BehaviorPage;
